import './../styles/responsive.scss';
import Header from './Header';
import Footer from './Footer';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export default function Bio() {
    return (
        <div className='bioDiv'>
            <Header />       
            <div className='row'>
                <NavLink to='/bio' style={{ textDecoration: 'none' }}><p className='navButton'>BIO</p></NavLink>
                <NavLink to='/' style={{ textDecoration: 'none' }}><p className='navButton'>PORTFOLIO</p></NavLink>                
                <NavLink to='/contact' style={{ textDecoration: 'none' }}><p className='activeNavButton'>CONTACT</p></NavLink>
            </div>      
                

            <div className='contactContainer'>
                <p className='contact'>Email: <a href="mailto:leah@leahbos.com" className='emailLink'>leah@leahbos.com</a></p>  

                <a href='https://github.com/LBOS89' target='_blank' className='contactLink'>  <p><FaGithub /> GitHub</p></a>
                <a href='https://www.linkedin.com/in/leah-bos/' target='_blank' className='contactLink'>
                <p><FaLinkedin /> LinkedIn</p></a>
            </div>
            <Footer />     
        </div>      
    );
}