import './../styles/responsive.scss';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function GroceryApp() {
    return (        
         <div className='contentDiv'>
            <Header />

            <div className='photoDisplay'>
                <h1 className='projectTitle'>Grocery Buddy: A React Native Mobile App</h1>

                <img className='displayPhoto' src={require('./../assets/groceryapp-home.png')} alt='mobile grocery app home screen' /> 

                <img className='displayPhoto' src={require('./../assets/groceryapp-list.png')} alt='mobile grocery app list screen' />

                <img className='displayPhoto' src={require('./../assets/groceryapp-compare.png')} alt='mobile grocery app compare screen' /> 

                <img className='displayPhoto' src={require('./../assets/groceryapp-usersubmissions.png')} alt='mobile grocery app user submission screen' /> 

                <img className='displayPhoto' src={require('./../assets/groceryapp-historyunfiltered.png')} alt='mobile grocery app history screen' />   

                <img className='displayPhoto' src={require('./../assets/groceryapp-admatch.png')} alt='mobile grocery app ad match screen' />
            </div>

            <Footer />
            
        </div>
    );
}