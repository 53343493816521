import './../styles/responsive.scss';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function ReactPizzaApp() {
    return (        
         <div className='contentDiv'>
            <Header />

            <div className='photoDisplay'>
                <h1 className='projectTitle'>One Free Pizza: A React Single-Page Application</h1>

                <img className='webpageDisplayPhoto' src={require('./../assets/home.PNG')} alt='react pizza app home screen' /> 

                <img className='webpageDisplayPhoto' src={require('./../assets/getyourpizza-filled.PNG')} alt='react pizza app order screen' />

                <img className='webpageDisplayPhoto' src={require('./../assets/getyourpizza-orderplaced.PNG')} alt='react pizza app order placed screen' /> 

                <img className='webpageDisplayPhoto' src={require('./../assets/loading orders.PNG')} alt='react pizza app loading screen' /> 

                <img className='webpageDisplayPhoto' src={require('./../assets/orders.PNG')} alt='react pizza app orders screen' />   

            </div>

            <Footer />
            
        </div>
    );
}