//import Home from './components/Home/Home';
import Header from './components/Header';
import Projects from './components/Projects';
import Bio from './components/Bio';
import Contact from './components/Contact';
import GroceryApp from './components/GroceryApp';
import ReactPizzaApp from './components/ReactPizzaApp';
import RestaurantApp from './components/RestaurantApp';
import WordPressProject from './components/WordPressProject';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (    
    <div>
        <Routes>
          <Route path='/' element={<Projects />} />
          <Route path='/portfolio' element={<Projects />} />
          <Route path='/bio' element={<Bio />} />          
          <Route path='/contact' element={<Contact />} />
          <Route path='/grocery-app' element={<GroceryApp />} />
          <Route path='/react-pizza-app' element={<ReactPizzaApp />} />
          <Route path='/restaurant-app' element={<RestaurantApp />} />
          <Route path='/wordpress-project' element={<WordPressProject />} />
        </Routes>
    </div>
    
  );
}

export default App;
