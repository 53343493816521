import './../styles/responsive.scss';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function WordPressProject() {
    return (        
         <div className='contentDiv'>
            <Header />

            <div className='photoDisplay'>
                <h1 className='projectTitle'>Simple Designs: A Custom WordPress Website</h1>

                <img className='webpageDisplayPhoto' src={require('./../assets/wp photo 1.PNG')} alt='wordpress site home screen' /> 

                <img className='webpageDisplayPhoto' src={require('./../assets/wp photo 2.PNG')} alt='wordpress site home screen' />

                <img className='webpageDisplayPhoto' src={require('./../assets/wp photo 3.PNG')} alt='wordpress site about screen' />  

            </div>

            <Footer />
            
        </div>
    );
}