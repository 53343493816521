import './../styles/responsive.scss';
import Header from './Header';
import Footer from './Footer';
import { FaUserGraduate, FaGithub, FaLinkedin } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export default function Bio() {
    return (
        <div className='bioDiv'>
            <Header />     
            <div className='row'>
                <NavLink to='/bio' style={{ textDecoration: 'none' }}><p className='activeNavButton'>BIO</p></NavLink>
                <NavLink to='/' style={{ textDecoration: 'none' }}><p className='navButton'>PORTFOLIO</p></NavLink>                
                <NavLink to='/contact' style={{ textDecoration: 'none' }}><p className='navButton'>CONTACT</p></NavLink>
            </div>                

            <div className='bioContainer'>
                <img className='headshot' src={require('./../assets/headshot.png')} alt='Leah Bos headshot' />
                <p>Hi! I'm Leah, a Front-End Developer building web and mobile apps with a focus on seamless functionality and clean code using JavaScript (including React and React Native), HTML5, CSS3, Sass and third party API integrations.</p>                    
                <h2 className='education'><FaUserGraduate /> Education</h2>
                <p>A 4.2 GPA President's Honour Roll graduate of the Web Development and Internet Applications program at Fanshawe College.</p>

                <NavLink to='/contact' style={{ textDecoration: 'none' }}><p className='innerLink'>Get In Touch</p></NavLink>

                <a href='https://github.com/LBOS89' target='_blank' className='contactLink'>  <p><FaGithub /> GitHub</p></a>
                <a href='https://www.linkedin.com/in/leah-bos/' target='_blank' className='contactLink'>
                <p><FaLinkedin /> LinkedIn</p></a>
            </div>
            <Footer />     
        </div>      
    );
}