import './../styles/responsive.scss';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Header() {  
    return (        
         <div className='header'>
             <NavLink to='/' ><img className='logo' src={require('./../assets/bos-logo.png')} alt='leah bos logo' /></NavLink>
        </div>
    );
}