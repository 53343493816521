import './../styles/responsive.scss';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function RestaurantApp() {
    return (        
         <div className='contentDiv'>
            <Header />

            <div className='photoDisplay'>
                <h1 className='projectTitle'>Dinner Time: A React Native Mobile App</h1>

                <img className='displayPhoto' src={require('./../assets/restaurantapp-login.png')} alt='mobile restaurant app login screen' /> 

                <img className='displayPhoto' src={require('./../assets/restaurantapp-loggedin.png')} alt='mobile restaurant app logged in screen' />

                <img className='displayPhoto' src={require('./../assets/restaurantapp-selector.png')} alt='mobile restaurant app selector screen' /> 

                <img className='displayPhoto' src={require('./../assets/restaurantapp-recommendation.png')} alt='mobile restaurant app recommendation screen' /> 

            </div>

            <Footer />
            
        </div>
    );
}