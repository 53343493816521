import './../styles/responsive.scss';
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { IoLogoJavascript } from 'react-icons/io';
import { FaWordpress, FaReact } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

export default function Projects() {
    return (        
         <div className='contentDiv'>
            <Header />
            <div className='row'>
                <NavLink to='/bio' style={{ textDecoration: 'none' }}><p className='navButton'>BIO</p></NavLink>
                <NavLink to='/' style={{ textDecoration: 'none' }}><p className='activeNavButton'>PORTFOLIO</p></NavLink>
                <NavLink to='/contact' style={{ textDecoration: 'none' }}><p className='navButton'>CONTACT</p></NavLink>
            </div>
            
            <div>
                <VerticalTimeline
                    lineColor='#003d3d'
                    layout='1-column-left'
                >
                    <VerticalTimelineElement
                        className='vertical-timeline-element--work'
                        contentStyle={{ background: '#003d3d', color: '#fff', width: '70vw', justifyContent: 'center', alignItems: 'center'}}
                        contentArrowStyle={{ borderRight: '7px solid #003d3d' }}
                        date='April 2022'
                        iconStyle={{ backgroundColor: '#121212', color: '#61dafb' }}
                        icon={<FaReact />}
                    >
                        <img className='websitePhoto' src={require('./../assets/simpledesigns.png')} alt='simple designs website photo' />

                        <a href='https://simple-designs.herokuapp.com/' target='_blank' className='link'>
                            <p className='phoneLink'>Check It Out</p>
                        </a>                  

                        <p className={window.innerWidth < 1180 ? 'projectDescription' : 'projectDescriptionRight'} >
                        A responsive SPA built with React and styled with Sass. Navigation with React Router. Developed and designed from scratch.
                        </p>
                    </VerticalTimelineElement>
                    
                    <VerticalTimelineElement
                        className='vertical-timeline-element--work'
                        contentStyle={{ background: '#003d3d', color: '#fff', width: '70vw', justifyContent: 'center', alignItems: 'center'}}
                        contentArrowStyle={{ borderRight: '7px solid #003d3d' }}
                        date='April 2022'
                        iconStyle={{ backgroundColor: '#121212', color: '#61dafb' }}
                        icon={<FaReact />}
                    >
                        <img className='websitePhoto' src={require('./../assets/groceryapp-allphotos.png')} alt='mobile grocery app photo' />                 

                        <p className={window.innerWidth < 1180 ? 'projectDescription' : 'projectDescriptionCenter'}>
                        A React Native mobile app project built with Expo and Firebase. Includes: 
                            {window.innerWidth < 1180 &&
                                <ul>
                                    <li>login/registration</li>
                                    <li>capturing user input to create categorized and revisable shopping lists</li>
                                    <li>direct product price comparisons</li>
                                    <li>budget setting, tracking, and alerting</li>
                                    <li>searchable histories</li>
                                    <li>access to and verification of other user submissions</li>
                                    <li>total savings calculations by store</li>
                                    <li>price per unit calculations</li>
                                    <li>ad matching</li>
                                </ul>
                            }
                            {window.innerWidth >= 1180 &&
                            <table>
                                <tr>
                                    <td>
                                        <ul>
                                            <li>login/registration</li>
                                            <li>capturing user input to create categorized and revisable shopping lists</li>
                                            <li>direct product price comparisons</li>
                                            <li>budget setting, tracking, and alerting</li>
                                            <li>searchable histories</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul> 
                                            <li>access to and verification of other user submissions</li>
                                            <li>total savings calculations by store</li>
                                            <li>price per unit calculations</li>
                                            <li>ad matching</li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                            }                        
                        </p>                    

                        <div className='buttonContainer'>
                            <NavLink to='/grocery-app' style={{ textDecoration: 'none' }}><p className='seeMoreButton'>See More</p></NavLink>
                        </div>   
                    </VerticalTimelineElement>
                    
                    <VerticalTimelineElement
                        className='vertical-timeline-element--work'
                        contentStyle={{ background: '#003d3d', color: '#fff', width: '70vw', justifyContent: 'center', alignItems: 'center'}}
                        contentArrowStyle={{ borderRight: '7px solid #003d3d' }}
                        date='April 2022'
                        iconStyle={{ backgroundColor: '#121212', color: '#61dafb' }}
                        icon={<FaReact />}
                    >
                        <img className='websitePhoto' src={require('./../assets/home.PNG')} alt='react app photo' />
                                        
                        <p className='projectSubheading'>See the code on <a href='https://github.com/LBOS89/react-project' target='_blank' className='link'> GitHub</a></p>                   

                        <p className='projectDescription'>
                        A React App project, with React Router and Axios. Includes navigation, form handling, retrieving, sending, and deleting data with API endpoints, page loading and conditional rendering. Styled with Sass.
                        </p>

                        <div className='buttonContainer'>
                            <NavLink to='/react-pizza-app' style={{ textDecoration: 'none' }}><p className='seeMoreButton'>See More</p></NavLink>
                        </div>   
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className='vertical-timeline-element--work'
                        contentStyle={{ background: '#003d3d', color: '#fff', width: '70vw', justifyContent: 'center', alignItems: 'center'}}
                        contentArrowStyle={{ borderRight: '7px solid #003d3d' }}
                        date='January 2022'
                        iconStyle={{ backgroundColor: '#c29a19', color: '#000'}}
                        icon={<IoLogoJavascript />}
                    >
                        <img className='websitePhoto' src={require('./../assets/nasa photo 1.PNG')} alt='nasa feed photo' />

                        <a href='https://m891ye.csb.app/' target='_blank' className='link'>
                            <p className='websiteLink'>Check It Out</p>
                        </a> 
                                        
                        <p className='projectSubheading'>See the code on <a href='https://github.com/LBOS89/nasa-challenge' target='_blank' className='link'> GitHub</a></p>                   

                        <p className='projectDescription'>
                        A quick challenge with a focus on retrieving,  displaying, filtering, and interacting with data from an API. Not responsive or optimized - strictly for demonstrating functionality. 
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className='vertical-timeline-element--work'
                        contentStyle={{ background: '#003d3d', color: '#fff', width: '70vw', justifyContent: 'center', alignItems: 'center'}}
                        contentArrowStyle={{ borderRight: '7px solid #003d3d' }}
                        date='December 2021'
                        iconStyle={{ backgroundColor: '#121212', color: '#61dafb' }}
                        icon={<FaReact />}
                    >
                        <img className='websitePhoto' src={require('./../assets/restaurantapp.png')} alt='mobile restaurant app photos' />
                                        
                        <p className='projectSubheading'>See the code on <a href='https://github.com/LBOS89/mobile-dev-restaurant-app' target='_blank' className='link'> GitHub</a></p>                   

                        <p className='projectDescription'>
                        My first React Native mobile app built using Expo. Includes user login/registration, a restaurant picker allowing users to select the type of food and a budget, a recommendation based on user input, and the option to send the recommendation to a friend.
                        </p>

                        <div className='buttonContainer'>
                            <NavLink to='/restaurant-app' style={{ textDecoration: 'none' }}><p className='seeMoreButton'>See More</p></NavLink>
                        </div> 
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className='vertical-timeline-element--work'
                        contentStyle={{ background: '#003d3d', color: '#fff', width: '70vw', justifyContent: 'center', alignItems: 'center'}}
                        contentArrowStyle={{ borderRight: '7px solid #003d3d' }}
                        date='August 2021'
                        iconStyle={{ backgroundColor: '#21759b', color: '#fff' }}
                        icon={<FaWordpress />}
                    >
                        <img className='websitePhoto' src={require('./../assets/wp photo 1.PNG')} alt='wordpress website photo' />

                        <p className='projectDescription'>
                        A CMS project built on WordPress.ORG on a WAMP stack, customized and SEO optimized. 
                        </p>

                        <div className='buttonContainer'>
                            <NavLink to='/wordpress-project' style={{ textDecoration: 'none' }}><p className='seeMoreButton'>See More</p></NavLink>
                        </div> 
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className='vertical-timeline-element--work'
                        contentStyle={{ background: '#003d3d', color: '#fff', width: '70vw', justifyContent: 'center', alignItems: 'center'}}
                        contentArrowStyle={{ borderRight: '7px solid #003d3d' }}
                        date='April 2021'
                        iconStyle={{ backgroundColor: '#c29a19', color: '#000'}}
                        icon={<IoLogoJavascript />}
                    >
                        <img className='projectPhoto' src={require('./../assets/moda.png')} alt='moda website photo' />

                        <a href='https://lbos89.github.io/ecommerce-project/' target='_blank' className='link'>
                            <p className='projectLink'>Check It Out</p>
                        </a> 

                        <p className='projectSubheading'>See the code on <a href='https://github.com/LBOS89/ecommerce-project' target='_blank' className='link'> GitHub</a></p>                     

                        <p className={window.innerWidth < 1180 ? 'projectDescription' : 'projectDescriptionLeft'}>
                        My first webpage, an ecommerce project in Semester 1 of the Web Development &amp; Internet Applications program at Fanshawe College. Built from a blank slate with Vanilla JavaScript, HTML5, and CSS3. A little rough around the edges, but hey, it's functional and responsive.
                        </p>
                    </VerticalTimelineElement>               
                </VerticalTimeline>
            </div>
            <Footer />
        </div>
    );
}